import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Tab,
  Nav,
  Accordion,
} from "react-bootstrap";
import config from "../../../coreFIles/config";
import F1 from "../images/gallery/F1.png";

import "../css/style.css";
import "../css/responsive.css";

const UpcomingEvent = () => {
  return (
    <>
      <div className="gallery">
        <Container>
          <Row>
            <Col md={4} className="mt-4 mt-md-0">
              <img src='images/eventNew/12.jpeg' style={{ height: 'auto', width: '100%' }} className="img-fluid" />
            </Col>
            <Col md={4} className="mt-4 mt-md-0">
              <img src='images/eventNew/10.jpg' style={{ aspectRatio: "1 / 1" }} className="g_img" />
            </Col>
            <Col md={4} className="mt-4 mt-md-0">
              <img src='images/eventNew/11.jpg' style={{ aspectRatio: "1 / 1" }} className="g_img" />
            </Col>
            <Col md={4} className="mt-4 mt-md-4">
              <img src='images/eventNew/7.jpg' style={{ height: 'auto', width: '100%' }} className="img-fluid" />
            </Col>
          </Row>
        </Container>


        {/* <div className="container">
          <div className="row">
            <div className="col-4">
              <img src='images/eventNew/12.jpeg' style={{ height: 'auto', width: '100%' }} className="img-fluid" />
            </div>
            <div className="col-4">
              <img src='images/eventNew/10.jpg' style={{ aspectRatio: "1 / 1" }} className="g_img" />
            </div>
            <div className="col-4">
              <img src='images/eventNew/11.jpg' style={{ aspectRatio: "1 / 1" }} className="g_img" />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-4">
              <img src='images/eventNew/7.jpg' style={{ height: 'auto', width: '100%' }} className="img-fluid" />
            </div>
          </div>
        </div> */}

        {/* <div className="d-flex justify-content-center">
                    <ul className="g_ul">
                        <li>
                            <a href="">
                                <figure>
                                    <img src='images/eventNew/6.jpg' style={{ objectFit: "contain" }} className="g_img" />
                                    <figcaption></figcaption>
                                </figure>
                            </a>
                        </li>
                        
                    </ul>
                </div> */}

        {/* <Row>
          <Col className="p-0">
            <img src={E1} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E2} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E3} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E4} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E5} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E6} className="img-fluid" />
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <img src={E1} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E2} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E3} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E4} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E5} className="img-fluid" />
          </Col>
          <Col className="p-0">
            <img src={E6} className="img-fluid" />
          </Col>
        </Row> */}

        {/* <Container>
          <Row>
            <Col lg={6} className="px-1">
              <div class="eventdetailImg">
                <img src={E1} />
              </div>
            </Col>
            <Col lg={6} className="px-1">
              <div className="collapsImg">
                <div className="nft_pic nft_multi">
                  <a className>
                    <img src={E4} />
                  </a>
                  <a className>
                    <img src={E1} />
                  </a>
                  <a className>
                    <img src={E3} />
                  </a>
                  <a className>
                    <img src={E6} />
                  </a>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg={6} className="px-1">
              <div className="collapsImg">
                <div className="nft_pic nft_multi">
                  <a className>
                    <img src={E7} />
                  </a>
                  <a className>
                    <img src={E5} />
                  </a>
                  <a className>
                    <img src={E2} />
                  </a>
                  <a className>
                    <img
                      src={E3}
                      style={{
                        background: "rgb(238, 238, 238)",
                        "object-fit": "contain",
                      }}
                    />
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={6} className="px-1">
              <div class="eventdetailImg">
                <img src={E1} />
              </div>
            </Col>
          </Row>
        </Container> */}
      </div>
      {/* <div className="gallery">
        <Container>
          <Row>
            <Col lg={6} className="px-1">
              <div class="eventdetailImg">
                <img src={F1} />
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};
export default UpcomingEvent;
