import React, { useEffect, useState } from "react";
import config from "../../../coreFIles/config";
import Pancakeswap from "../images/partner/pancakeswap.png";
import Certhik from "../images/partner/certhik.png";
import Coinstore from "../images/partner/coinstore.png";
import Coingenko from "../images/partner/coingenko.png";
import "../css/style.css";
import "../css/responsive.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay } from "swiper";

const Partnerslider = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 8,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <a>
            <a
              href="https://pancakeswap.finance/swap?inputCurrency=0x3e81Aa8d6813Ec9D7E6ddB4e523fb1601a0e86F3&outputCurrency=0x55d398326f99059fF775485246999027B3197955"
              target="_blank"
            >
              <img src={Pancakeswap} />
            </a>
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href="https://skynet.certik.com/projects/mr-mint?utm_source=CoinGecko&utm_campaign=AuditByCertiKLink"
            target="_blank"
          >
            <img src={Certhik} />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.coinstore.com/#/" target="_blank">
            {" "}
            <img src={Coinstore} />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.coingecko.com/en/coins/mr-mint" target="_blank">
            <img src={Coingenko} />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href="https://pancakeswap.finance/swap?inputCurrency=0x3e81Aa8d6813Ec9D7E6ddB4e523fb1601a0e86F3&outputCurrency=0x55d398326f99059fF775485246999027B3197955"
            target="_blank"
          >
            <img src={Pancakeswap} />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href="https://skynet.certik.com/projects/mr-mint?utm_source=CoinGecko&utm_campaign=AuditByCertiKLink"
            target="_blank"
          >
            <img src={Certhik} />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.coinstore.com/#/" target="_blank">
            {" "}
            <img src={Coinstore} />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.coingecko.com/en/coins/mr-mint" target="_blank">
            <img src={Coingenko} />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href="https://pancakeswap.finance/swap?inputCurrency=0x3e81Aa8d6813Ec9D7E6ddB4e523fb1601a0e86F3&outputCurrency=0x55d398326f99059fF775485246999027B3197955"
            target="_blank"
          >
            <img src={Pancakeswap} />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href="https://skynet.certik.com/projects/mr-mint?utm_source=CoinGecko&utm_campaign=AuditByCertiKLink"
            target="_blank"
          >
            <img src={Certhik} />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.coinstore.com/#/" target="_blank">
            {" "}
            <img src={Coinstore} />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.coingecko.com/en/coins/mr-mint" target="_blank">
            <img src={Coingenko} />
          </a>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
export default Partnerslider;
